import React from 'react'
import { graphql } from 'gatsby'

import { Button, Cherepovets, Column, FromAuthors, Heroes, Layout, Map, Row, Video } from '../components'
import { TEXTS } from '../constants'
import galleryIcon from '../images/icons/gallery.svg'

const ComponentName = () => (
    <Layout pageTitle={TEXTS.siteTitle}>
        <Heroes />
        <Video />
        <Row>
            <Column width={40}>
                <Cherepovets />
                <Button to="gallery" text={TEXTS.gallery} icon={galleryIcon} />
                <FromAuthors />
            </Column>
            <Column width={60}>
                <Map />
            </Column>
        </Row>
    </Layout>
)

export const query = graphql`
  {
    map: file(relativePath: { eq: "map.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ComponentName
